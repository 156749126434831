<template>
  <div class="col-12 mt-3 pt-3 px-3">
    <div class="row m-0 justify-content-center">
      <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
        <div class="card_title">
          STEP {{page}} of 3
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepCount',
  props: ['page']
}
</script>

<style scoped>

.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 0.9rem;
  }
}
</style>
